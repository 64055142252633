@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --homepage-background: #5b6a82;
  --homepage-primary-text: black;
  --homepage-secondary-text: white;
  --homepage-button-background: #6fc2e3;
  --homepage-button-second-background: #def0ff;
  --homepage-button-second-text: #4d99b6;
  --homepage-button-second-text: #1e5f78;
  --primary-color: rgb(15, 157, 88);
  --secondary-color: rgb(255, 255, 255);

  --main-color: #61dafb;
  --sub-color: #20839e;
  --color: #078989;
  --header: #00b5be;
  --header-main: #039198;
  --header-main: #29d4dd;

  --trial: #cfffdc !important;
  --man: rgb(200, 196, 196);

  scroll-behavior: smooth !important;
  transition: "400ms all ease-in";
}

body {
  margin: 0;
  box-sizing: content-box;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
  /* box-sizing: border-box; */
}
.css-ahj2mt-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
  background: rgba(176, 186, 195, 0.19) !important;
  margin: 0 !important;
  border-color: red !important;
  outline: none !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:hover {
  background: white !important;
  border: none !important;
}
.unit-content::-webkit-scrollbar {
  width: 5px !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */

/* .questions-container::-webkit-scrollbar {
  width: 5px !important;
  height: 100% !important;
} */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1e5f78;
  border-radius: 10px;
}

/* resume container style */
.resume-container {
  height: 168px;
  display: flex;
  cursor: pointer;
  margin: 15px 0 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #5b6a82;
  margin-bottom: 15px !important;
}
.talent-form::-webkit-scrollbar {
  width: 0;
}

/* header */

/* .header {
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );

  box-shadow: 0px 0px 40px -25px rgba(0, 0, 0, 0.5);

  border-radius: 10px;
  z-index: 10 !important;
  height: 100px !important;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;

  height: "100px";
  display: "flex";

  background: white;
  color: #f1f1f1;
  width: 100%;

  position: fixed;
  top: 0px;
} */
.medical-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px !important;
  line-height: 160.9%;
  /* or 77px */

  letter-spacing: 0.01em;

  color: #00b5be;
}
/* body section */
.body-section {
  /* height: calc(100vh-100px); */
  height: calc(100vh - 120px);
}

.top-container {
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center;
}

.content {
  padding: 16px;
  background: white;
}

.sticky + .content {
  padding-top: 102px;
}

.questions-container {
  /* height: calc(100vh - 200px) !important; */
  overflow: auto;
}
.course-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 70vh;
  max-height: fit-content;
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  margin-top: 40px;
}
.testimonial-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 70vh;
  max-height: fit-content;
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  margin-top: 40px;
}
.quest-container {
  height: calc(100vh - 100px) !important;
  overflow: auto;
}

.content-container {
  height: fit-content !important;
  overflow: auto;
}

@media screen and (min-width: 1200px) {
  .content-container {
    height: calc(100vh - 160px) !important;
    overflow: auto;
  }
}

/* Add Course css */

.no-course {
  height: calc(100vh - 562px);
}

.course-image-container {
  min-width: 170px;
  max-width: 230px;
  min-height: 170px;
  max-height: 230px;
  margin: 0 auto 30px auto;
}
.course-image-container img {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
  object-fit: fill;
}
figcaption {
  margin: 20px 0 30px 0;
  color: #2a292a;
  text-align: center;
}

.search-bar {
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
}

.course-card {
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(0, 181, 190, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 26px;
}

/* Footer css */
.footer {
  background: #00b5be !important;
  padding: 30px 0px;
  font-family: "Play", sans-serif;
  text-align: center;
  position: sticky;
  left: 0px;
  width: 100%;
  bottom: 0;
  color: black !important;
}

.footer .row {
  width: 100%;
  margin: 0px 0;
  padding: 5px 0;
  color: black;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.footer .row a {
  text-decoration: none;
  color: black;
  transition: 0.5s;
  width: auto;
}

.footer .row a:hover {
  color: #039198;
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}
.footer .row ul li a {
  color: white;
  font-weight: 500;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
}

@media (max-width: 720px) {
  .footer {
    text-align: left;
    padding: 5%;
  }
  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: left;
  }
  .footer .row a i {
    margin: 0% 3%;
  }
}

.filter-container {
  max-height: calc(100vh - 400px);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1e5f78;
  border-radius: 10px;
}

/* taken from old */

.question-choice {
  font-size: "18px";
  /* width: 100%; */
  transition: all 400ms ease-in-out;
  font-family: sans-serif;
}

/* .question-choice:hover {
  border-collapse: collapse;
  background-color: #f2f2f2;
  cursor: pointer;
  color: black;
} */

.wrong-choice {
  border-collapse: collapse;
  background-color: red;
}
.wrong-choice:hover {
  border-collapse: collapse;
  background-color: red;
}

.correct-choice {
  color: black !important;
  font-weight: 700;
  border-collapse: collapse;
  background-color: #29ad50;
  transition: all 400ms ease-in-out;
}
.correct-choice:hover {
  color: black !important;
  font-weight: 700;
  border-collapse: collapse;
  background-color: #29ad50;
  transition: all 400ms ease-in-out;
}
.css-ahj2mt-MuiTypography-root {
  font-family: sans-serif !important;
}

#Questions,
#Testimonials {
  scroll-margin-top: 100px;
}

#Contact {
  scroll-margin-top: 100px;
}

/* Questions header link drop down css */

.dropdown-content {
  margin-top: 7px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content .dropdown-link {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.dropdown-content .dropdown-link:hover {
  background-color: #ddd;
  color: black;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* Question horizontal swiper css */
.swiper-slide {
  min-width: 250px;
  max-width: 300px;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent;
  color: rgb(0, 0, 0) !important;
  fill: rgb(0, 0, 0) !important;
  stroke: rgb(0, 0, 0) !important;
}

/* Question horizontal swiper css */
.testimonials-card {
  transition: all 400ms ease-in-out;
}
.testimonials-card:hover {
  border: 1px solid black;
  box-shadow: inset 100px 100px 100px 100px #e7ebf0;
  /* background-color:rgba(97, 218, 251, 1); */
}
/* Contact form css */
.input-username {
  border: none;
  border-radius: 40px;
  background-color: white;
}
.contact-message {
  background: white;
  border: none;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* Footer css */
.footer {
  background: #20232a;
  padding: 30px 0px;
  font-family: "Play", sans-serif;
  text-align: center;
  position: sticky;
  left: 0px;
  /* width: 100vw; */
  bottom: 0;
  /* border: 10px solid green; */
  /* width: 400px !important; */
}

.footer .row {
  /* width: 100%; */
  margin: 10px 0;
  padding: 5px 0;
  color: gray;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer .row a {
  text-decoration: none;
  color: white;
  transition: 0.5s;
  width: auto;
}

.footer .row a:hover {
  color: rgb(97, 218, 251);
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
}

@media (max-width: 720px) {
  .footer {
    text-align: left;
    /* padding: 5%; */
  }
  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: left;
  }
  .footer .row a i {
    margin: 0% 3%;
  }
}

/* Unit Drop Card Css */
.unit-drop-card {
  min-height: 50px !important;
}

.questions-container {
  background: yellow;
  overflow: auto;
  /* max-height: 1000px; */
  /* calc(100vh-400px); */
}
.question-container-card {
  /* min-height: 500px; */
  overflow: auto;
  max-height: max-content;
}
.units-container {
}

.course-page {
  background: #e7ebf0;
  /* min-height: calc(100vh - 400px) !important; */
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1000px !important;
}

.quiz-container {
  min-height: calc(100vh - 600px) !important;
}

/* check mark css */

.checkbox {
  position: relative;
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.checkbox:hover {
  border-color: #666;
}

.checkmark-line {
  position: absolute;
  top: 11px;
  left: 4px;
  width: 9px;
  height: 2px;
  background-color: green;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.checkmark-line--short {
  top: 16px;
  left: 10px;
  width: 14px;
  transform: rotate(-45deg);
}

.checkmark {
  stroke: green;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 24;
  stroke-dashoffset: 24;
  animation: draw-checkmark 0.3s ease forwards;
}

.circle {
  stroke-width: 2;
  stroke-dasharray: 159;
  stroke-dashoffset: 159;
  animation: draw-circle 0.3s ease forwards;
}

@keyframes draw-checkmark {
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 44;
  }
}

@keyframes draw-circle {
  from {
    stroke-dashoffset: 159;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.circle {
  stroke-width: 2;
  stroke-dasharray: 159;
  stroke-dashoffset: 159;
  animation: draw-circle 0.3s ease forwards;
}

.wrong-x {
  stroke: #ff3b30;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 24;
  stroke-dashoffset: 24;
  animation: draw-wrong-x 0.3s ease forwards;
}

@keyframes draw-circle {
  from {
    stroke-dashoffset: 159;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-wrong-x {
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 44;
  }
}

#testom-container {
  width: calc(100% - 100px);
}
.about-container {
  width: 100%;
  position: relative;
}

.about-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 20px 0 20px;
  transform: translateX(-50%);
}
